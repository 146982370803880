<template src="./myDoctors.html">
</template>

<script>
import mixins from "../../../mixins/mixins.js";
import { PhoneIcon } from "vue-feather-icons";
import customIcon from "vue-icon/lib/vue-feather.esm";
export default {
  mixins: [mixins],
  components: {
    PhoneIcon,
    customIcon,
  },
  data: () => ({
    doctors: [],
    activeUserImg: "",
    selected: [],
  }),
  mounted: function () {
    var vm = this;
    vm.init();
  },
  methods: {
    init() {
      var vm = this;
      vm.getDoctors();
    },
    getDoctors() {
      const vm = this;
      vm.axios.get(vm.$store.state.filooServer + "patient/my-doctors", {
        headers: {
          Authorization: vm.$session.get("bearer"),
        },
      })
        .then(function (response) {
          vm.doctors = response.data.content;
          for (const doc in vm.doctors) {
            const phone = vm.doctors[doc].doctor.phone;
            const phoneLength = phone === null ? 0 : phone.length;
            if (phoneLength > 16) vm.doctors[doc].doctor.phone = null;
            if (phoneLength < 11) vm.doctors[doc].doctor.phone = null;

            vm.doctors[doc].crm = vm.testNullUndefinedEmpty(vm.doctors[doc].doctor.crmState)? "Não disponível" : vm.doctors[doc].doctor.crmState.toUpperCase() + " " + vm.doctors[doc].doctor.crmNumber;
          }
        })
    },
    getDay(dateHour) {
      return dateHour.substring(0, 10);
    },
    getHour(dateHour) {
      return dateHour.substring(12, 16);
    },
    getDayOfWeek(date) {
      date = date.substring(1, 10);
      var dayOfWeek = new Date(date).getDay();
      return isNaN(dayOfWeek) ? null : ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"][dayOfWeek];
    },

  },
}
</script>

<style lang="scss" src="./myDoctors.scss">

</style>
